import type {InputHTMLAttributes, ReactNode} from 'react';
import cn from 'classnames';

import Typography from '@/components/base/elements/Typography/Typography';
import {twMerge} from '@/stylesheets/twMerge';

import type {INPUT_TYPES} from './InputTypes';

export interface BaseInputProps extends InputHTMLAttributes<HTMLInputElement> {
  'aria-description'?: string;
  error?: any;
  helpText?: string | ReactNode;
  hint?: string | ReactNode;
  id: string;
  label?: string;
  type?: (typeof INPUT_TYPES)[number];
  wrapperClassName?: string;
  errorClassName?: string;
  inputOnErrorClassName?: string;
  locale?: string;
  placeholder?: string;
  info?: string | ReactNode;
}

/**
 * @deprecated Use `base/modules/TextInput` instead
 */
export default function BaseInput({
  error,
  helpText,
  hint,
  id,
  label = '',
  info,
  type = 'text',
  wrapperClassName = '',
  locale,
  placeholder,
  inputOnErrorClassName,
  errorClassName,
  ...inputProps
}: BaseInputProps) {
  const errorId = `${id}-error`;
  const hintId = `${id}-hint`;
  const helpTextId = `${id}-help-text`;
  const describedBy = cn(inputProps['aria-describedby'], {
    [errorId]: error,
    [hintId]: hint,
    [helpTextId]: helpText,
  });

  let errorText;
  const errorType = typeof error;
  if (errorType === 'string') {
    errorText = error;
  } else if (errorType === 'object' && error?.errorMessage) {
    errorText = error.errorMessage;
  }

  const errorProps = errorText
    ? {
        dangerouslySetInnerHTML: {__html: errorText},
      }
    : {children: error};

  return (
    <div
      className={twMerge(
        cn('mb-4 flex-1 text-left', {
          'flex flex-row-reverse items-start': type === 'checkbox',
        }),
        wrapperClassName,
      )}
    >
      {/* In ja locale, the labels are placed above the inputs */}
      {locale === 'ja' ? (
        <label htmlFor={id} className="block mb-1">
          {label}
          <div className="block mb-2 text-xs opacity-70">{info}</div>
        </label>
      ) : (
        <Typography
          as="label"
          htmlFor={id}
          className={cn(
            {
              'sr-only': !inputProps.value && type !== 'checkbox',
            },
            {
              'absolute py-2 px-[0.9rem]': type !== 'checkbox',
            },
            {
              'mt-sm': type === 'checkbox',
            },
          )}
        >
          {label}
        </Typography>
      )}
      <input
        key={id}
        {...inputProps}
        aria-describedby={describedBy}
        className={twMerge(
          cn(
            'text-black bg-white p-[0.9em] rounded w-full inline-block border border-transparent shadow-[0_0_0_1px] shadow-legacy-gray-70 ease-[ease] duration-300 transition-all focus:shadow-[0_0_0_2px] focus:shadow-legacy-green-70',
            {
              'pt-6 pb-[0.3em]': locale !== 'ja' && inputProps.value,
              'shadow-[0_0_0_2px] shadow-legacy-pink-70 focus:shadow-[0_0_0_2px] focus:shadow-legacy-pink-70':
                error,
              [`${inputOnErrorClassName}`]: inputOnErrorClassName && error,
              'shadow-[0_0_0_2px] shadow-legacy-cyan-20 focus:shadow-[0_0_0_2px] focus:shadow-legacy-cyan-20':
                hint,
            },
          ),
          inputProps.className,
        )}
        id={id}
        name={inputProps.name || id}
        type={type}
        placeholder={placeholder}
      />
      {error ? (
        <span
          className={twMerge(
            'shadow-[0_0_0_2px] shadow-legacy-pink-70 text-white bg-legacy-pink-70 block py-[0.4em] px-[0.9em] text-xs font-medium rounded-[0_0_4px_4px] leading-[1.6em]',
            errorClassName,
          )}
          id={errorId}
          {...errorProps}
        />
      ) : (
        hint && (
          <span
            className="shadow-[0_0_0_2px] shadow-legacy-cyan-20 text-black bg-legacy-cyan-20 block py-[0.4em] px-[0.9em] text-xs font-medium rounded-[0_0_4px_4px] leading-[1.6em]"
            id={hintId}
          >
            {hint}
          </span>
        )
      )}
      {helpText && (
        <span
          className="py-0 px-[0.9em] my-4 mx-0 block leading-[1.6em]"
          id={helpTextId}
        >
          {helpText}
        </span>
      )}
    </div>
  );
}
